.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@font-face {
  font-family: "Lobster-Regular";
  src: local("Lobster-Regular"),
   url("./fonts/Lobster-Regular.ttf") format("truetype");
}
  

@font-face {
  font-family: "Questrial-Regular";
  src: local("Questrial-Regular"),
   url("./fonts/Questrial-Regular.ttf") format("truetype");
}
  

.font-face-lobster {
  font-family: "Lobster-Regular";
  font-size: 10rem;
  margin: 1em;
  margin-bottom: 10px;
}

.text {
  font-family: "Questrial-Regular";
  color: black;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  font-size: 2rem;
  margin: 25px;
}


.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(148, 100, 255);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
